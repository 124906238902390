

































import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import getPasswordRecoveryMethods from "@/api/requests/getPasswordRecoveryMethods";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";
import TCpfInput from "../core/TCpfInput.vue";

export default Vue.extend({
  name: "FormGetRecoverCode",

  components: {
    TBtn,
    TCpfInput,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  data: () => ({
    valid: true,
    loading: false,
    rules: {
      email: [
        (v: string) => !!v || "O campo e-mail é obrigatório",
        (v: string) => /.+@.+\..+/.test(v) || "O e-mail digitado é inválido",
      ],
    },
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
    async submit() {
      try {
        if (this.form.validate()) {
          this.loading = true;
          const methods = await getPasswordRecoveryMethods(this.user.document);
          this.$emit("submit", methods);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          if (error.response?.data.message) {
            this.$snackbar(error.response?.data.message, "error");
          } else {
            this.$snackbar(error.message, "error");
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
