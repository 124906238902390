












import Vue from "vue";
import TSectionHeader from "@/components/core/TSectionHeader.vue";

export default Vue.extend({
  name: "TSection",

  props: {
    header: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },

  components: {
    TSectionHeader,
  },
});
