

















import Vue from "vue";

import { State as SnackbarState } from "@/store/modules/snackbar";

export default Vue.extend({
  name: "SnackBar",

  computed: {
    color(): string {
      return this.snackbar.color;
    },
    message(): string {
      return this.snackbar.message;
    },
    snackbar(): SnackbarState {
      return this.$store.state.snackbar;
    },
    timeout(): number {
      return this.snackbar.timeout;
    },
    maxWidth(): number {
      return this.$vuetify.breakpoint.width - 32;
    },
    minWidth(): number | undefined {
      if (!this.$vuetify.breakpoint.mobile) return undefined;

      return this.$vuetify.breakpoint.width - 32;
    },
    visible: {
      get(): boolean {
        return this.snackbar.visible;
      },
      set(): void {
        this.$store.commit("snackbar/hide");
      },
    },
  },
});
