import { AxiosRequestConfig } from "axios";
import store from "@/store";

export default function auth(config: AxiosRequestConfig): AxiosRequestConfig {
  if (store.state.user.token !== null) {
    config.headers["Authorization"] = `Bearer ${store.state.user.token.access_token}`;
  }

  return config;
}
