<template>
  <v-sheet class="d-flex align-center" color="secondary" dark>
    <t-container class="text-h5">
      <v-row>
        <v-col class="d-flex align-center" md="6" cols="12">
          <v-icon size="30">
            {{ icon }}
          </v-icon>
          <span class="mx-2 titleheader">
            {{ header }}
          </span>
        </v-col>
        <v-spacer />
        <slot name="input"></slot>
      </v-row>
    </t-container>
  </v-sheet>
</template>

<script>
export default {
  name: "TSectionHeader",

  props: {
    header: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
@media screen and (max-width: 350px) {
  .row .titleheader {
    font-size: 20px;
  }
}
</style>
