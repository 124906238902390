import Setting from "@/types/Setting";

type HasSetting = {
  settings: Setting[];
};

export const settings = {
  match(entity: unknown & HasSetting, tag: string): boolean {
    const candidate = entity.settings.find((s) => s.tag === tag);

    if (candidate === undefined) return false;

    return candidate.default;
  },
};

declare module "vue/types/vue" {
  interface Vue {
    $settings: {
      match(entity: unknown & HasSetting, tag: string): boolean;
    };
  }
}
