



































import Vue from "vue";

import User from "@/types/User";

import TCard from "@/components/core/TCard.vue";
import { DialogConfig } from "@/store/modules/dialog";
import { PasswordToken } from "@/api/requests/getPasswordRecoveryToken";
import TClickerTicketLogo from "@/components/core/TClickerTicketLogo.vue";
import FormGetRecoverCode from "@/components/User/FormGetRecoverCode.vue";
import FormValidateRecoverCode from "@/components/User/FormValidateRecoverCode.vue";
import FormRecoverPassword from "@/components/User/FormRecoverPassword.vue";
import FormSearchAccount from "@/components/User/FormSearchAccount.vue";
import RecoveryMethod from "@/types/RecoveryMethod";

export default Vue.extend({
  name: "PasswordRecover",

  components: {
    TCard,
    TClickerTicketLogo,
  },

  data: () => ({
    verified: false,
    user: {} as User,
    sent: false,
    step: 1,
    steps: [
      {
        id: 1,
        name: "Encontrar sua conta",
        component: FormSearchAccount,
      },
      {
        id: 2,
        name: "Escolher método de identificação",
        component: FormGetRecoverCode,
      },
      {
        id: 3,
        name: "Validar identificação",
        component: FormValidateRecoverCode,
      },
      {
        id: 4,
        name: "Escolher nova senha",
        component: FormRecoverPassword,
      },
    ],
    token: {} as PasswordToken,
    methods: [] as RecoveryMethod[],
  }),

  methods: {
    async validate($event: PasswordToken | RecoveryMethod[]): Promise<void> {
      const steps: Record<number, () => boolean | Promise<boolean | void>> = {
        [1]: () => {
          this.methods = $event as RecoveryMethod[];
          return true;
        },
        [2]: () => true,
        [3]: () => {
          this.token = $event as PasswordToken;
          return true;
        },
        [4]: () => this.submit(),
      };

      const valid = !steps[this.step] || (await steps[this.step]());

      if (!valid) return;

      this.step++;
    },
    close() {
      this.$dialog.hide();
    },
    submit() {
      this.$snackbar("Senha alterada com sucesso!", "success");
      this.close();
      return true;
    },
  },

  computed: {
    dialog(): DialogConfig {
      return this.$store.state.dialog.config;
    },
  },
});
