import axios from "axios";
import auth from "./interceptors/auth";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(auth);

export default api;
