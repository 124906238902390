














import Vue, { PropType } from "vue";

import User from "@/types/User";

import FormUserAddress from "./FormAddress.vue";
import FormUserBasicData from "./FormUserBasicData.vue";
import TStepper, { Step } from "@/components/core/TStepper.vue";

export default Vue.extend({
  name: "FormNewUser",

  components: {
    FormUserAddress,
    FormUserBasicData,
    TStepper,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    step: 1,
    steps: [
      {
        step: 1,
        label: "Dados Pessoais",
        editable: true,
      },
      {
        step: 2,
        label: "Dados de Endereço",
      },
    ] as Step[],
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user: {
      get(): User {
        return this.value;
      },
      set(user: User) {
        this.$emit("input", user);
      },
    },
  },

  methods: {
    submit() {
      this.$emit("submit");
    },
    reset() {
      this.step = 1;
    },
  },
});
