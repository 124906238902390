export default function currency(value: string): string {
  if (typeof value == "string") {
    value = value.replace(",", ".");
  }

  const numericalValue = parseFloat(value);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(numericalValue);
}
