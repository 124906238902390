



































































































import Vue from "vue";
import TClickerTicketLogoHeader from "@/components/core/TClickerTicketLogoHeader.vue";
import AppBarItem from "@/types/AppBarItem";

export default Vue.extend({
  name: "TheAppBar",
  components: {
    TClickerTicketLogoHeader,
  },
  data() {
    return {
      menu: null,
      drawer: false,
      modal: false,
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    name(): string {
      return this.$store.state.user.data.name;
    },
    logged(): boolean {
      return this.$store.state.user.data.name !== undefined;
    },
    showTicketsMenu(): boolean {
      if (this.$route.name === null) return true;
      if (this.$route.name === undefined) return true;

      const exclude = [""];

      return !exclude.includes(this.$route.name);
    },
    menus(): AppBarItem[] {
      if (this.isMobile) {
        return [
          {
            label: "Home",
            icon: "mdi-home-outline",
            callback: () => this.$router.push("/"),
            condition: true,
          },
          {
            label: "Meus Ingressos",
            icon: "mdi-ticket-confirmation-outline",
            callback: () => this.$router.push("/ticket/payments"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Meus Dados",
            icon: "mdi-pencil",
            callback: () => this.$router.push("/user/data"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Acesse Sua Conta",
            icon: "mdi-account-outline",
            callback: () => this.$dialog.show("/login"),
            condition: !this.$store.state.user.logged,
          },
          {
            label: "Fale conosco",
            icon: "mdi-chat-question-outline",
            callback: () => this.$router.push("/suporte"),
            condition: true,
          },
          {
            label: "Instalar APP",
            icon: "mdi-cellphone-arrow-down",
            classes: "browser-only",
            callback: () => this.$store.commit("app/pwa"),
            condition: window.pwa !== null && !this.$store.state.app.pwa.installed,
          },
          {
            label: `Sair`,
            icon: "mdi-logout",
            callback: () => {
              this.$store.commit("user/logout");
              this.$store.commit("cart/reset");
              this.$router.push("/");
            },
            condition: this.$store.state.user.logged,
          },
        ].filter((item: AppBarItem) => item.condition);
      } else {
        return [
          {
            label: "Home",
            icon: "mdi-home-outline",
            callback: () => this.$router.push("/"),
            condition: true,
          },
          {
            label: "Meus Ingressos",
            icon: "mdi-ticket-confirmation-outline",
            callback: () => this.$router.push("/ticket/payments"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Meus Dados",
            icon: "mdi-pencil",
            callback: () => this.$router.push("/user/data"),
            condition: this.$store.state.user.logged,
          },
          {
            label: "Acesse Sua Conta",
            icon: "mdi-account-outline",
            callback: () => this.$dialog.show("/login"),
            condition: !this.$store.state.user.logged,
          },
          {
            label: `Olá ${
              this.$store.state.user.data.name
                ? this.$store.state.user.data.name.split(" ")[0]
                : this.$store.state.user.data.name
            }!`,
            icon: "mdi-account-outline",
            callback: () => true,
            condition: this.$store.state.user.logged,
          },
          {
            label: `Sair`,
            icon: "mdi-logout",
            callback: () => {
              this.$store.commit("user/logout");
              this.$store.commit("cart/reset");
              this.$router.push("/");
            },
            condition: this.$store.state.user.logged,
          },
        ].filter((item: AppBarItem) => item.condition);
      }
    },
  },
});
