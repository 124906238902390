import api from "@/api";

interface UserExistsResponse {
  exists: boolean;
}

export default async function userExists(method: string, value: string): Promise<boolean> {
  const response = await api.get<UserExistsResponse>(`/api/user/${method}/check`, {
    params: {
      [method]: value,
    },
  });

  return response.data.exists;
}
