






























import Vue from "vue";
import TCard from "@/components/core/TCard.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TClickerTicketLogo from "@/components/core/TClickerTicketLogo.vue";

import getUserData from "@/api/requests/getUserData";
import getAccessToken from "@/api/requests/getAccessToken";
import { DialogConfig } from "@/store/modules/dialog";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";

export default Vue.extend({
  name: "UserLogin",

  components: {
    TCard,
    TTextInput,
    TClickerTicketLogo,
  },

  data: () => ({
    valid: false,
    email: "",
    loading: false,
    password: "",
    url: "",
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
  },

  methods: {
    async login() {
      if (!this.form.validate()) return;

      this.loading = true;

      try {
        const { email, password } = this;

        const { data } = await getAccessToken(email, password);

        this.$store.commit("user/setToken", data);

        const user = await (await getUserData()).data;

        this.$store.commit("user/setUser", user);

        this.$snackbar("Login feito com sucesso!", "success");

        this.loading = false;
        this.close();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          if (error.response?.status == 400) {
            this.$snackbar("E-mail ou senha incorretos!", "error");
          } else {
            this.$snackbar("Não foi possível realizar seu login, entre em contato com o suporte.", "error");
          }
        } else {
          this.$snackbar("Não foi possível realizar seu login, entre em contato com o suporte.", "error");
        }
      } finally {
        this.loading = false;
      }
    },
    register() {
      this.$dialog.show("/register");
    },
    forgotPassword() {
      this.$dialog.show("/recover");
    },
    close() {
      this.$dialog.hide();
    },
  },
});
