












import Vue, { PropType } from "vue";

import validateEmail from "@/utils/validateEmail";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TEmailInput",

  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "E-mail",
    },
    rules: {
      type: Array as PropType<Array<(v: string) => boolean | string> | []>,
      default: () => [],
    },
    value: {
      type: String,
      default: undefined,
    },
  },

  components: {
    TTextInput,
  },

  computed: {
    validationRules(): Array<(v: string) => boolean | string> | [] {
      const validateEmail = (email?: string): boolean | string => {
        if (!email) return true;

        return this.validateEmail(email) || "E-mail inválido";
      };

      return [...this.rules, validateEmail];
    },
  },

  methods: {
    validateEmail,
    validate(): boolean {
      const field = this.$refs["field"] as Vue & { validate(): boolean };
      return field.validate();
    },
  },
});
