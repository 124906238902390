import { VuetifyThemeVariant } from "vuetify/types/services/theme";

const light: Partial<VuetifyThemeVariant> = {
  primary: "#EA8300",
  secondary: "#494949",
  tertiary: "#83CC6F",
  "dark-background": "#242424",
  background: "#eee",
  "content-background": "#F8F8F8",
  heading: "#fff",
  body: "#D4D4D4",
};

export default { light };
