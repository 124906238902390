







































































































import Vue, { PropType } from "vue";

import Address from "@/types/Address";

import TBtn from "@/components/core/TBtn.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TCepInput from "@/components/core/TCepInput.vue";

export default Vue.extend({
  name: "FormAddress",

  props: {
    value: {
      type: Object as PropType<Address>,
      default: () => ({} as Address),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    TBtn,
    TTextInput,
    TCepInput,
  },

  data: () => ({
    valid: true,
    policy: false,
  }),

  computed: {
    zipcode: {
      get(): string {
        return this.address.zipcode;
      },
      set(zipcode: string): void {
        this.update("zipcode", zipcode);
      },
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    address(): Address {
      return this.value;
    },
  },

  methods: {
    submit() {
      if (!this.form.validate()) return;

      this.$emit("submit");
    },
    update(prop: string, value: string) {
      this.$emit("input", { ...this.address, [prop]: value });
    },
    fillForm(address: Address) {
      this.$emit("input", { ...this.address, ...address });
    },
  },
});
