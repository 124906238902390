

























import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import axios from "axios";
import getPasswordRecoveryToken from "@/api/requests/getPasswordRecoveryToken";

export default Vue.extend({
  name: "FormValidateRecoverCode",

  components: {
    TBtn,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  data: () => ({
    valid: true,
    loading: false,
    code: "",
    rules: {
      code: [(v: string) => !!v || "O código é obrigatório"],
    },
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
    async submit() {
      try {
        if (this.form.validate()) {
          this.loading = true;

          const token = await getPasswordRecoveryToken(this.user.document, this.code);
          this.$emit("submit", token);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          this.$snackbar("Código não encontrado", "error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
