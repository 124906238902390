














































































































import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import TDateInput from "@/components/core/TDateInput.vue";
import TEmailInput from "@/components/core/TEmailInput.vue";
import TPasswordInput from "@/components/core/TPasswordInput.vue";
import TPhoneInput from "@/components/core/TPhoneInput.vue";
import TTextInput from "@/components/core/TTextInput.vue";
import TSelect from "@/components/core/TSelect.vue";
import TCpfInput from "@/components/core/TCpfInput.vue";
import userExists from "@/api/requests/userExists";
import getAvaibleGenders from "@/api/requests/getAvaibleGenders";
import GenderData from "@/types/GenderData";

export default Vue.extend({
  name: "FormUserBasicData",

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
  },

  components: {
    TBtn,
    TDateInput,
    TEmailInput,
    TPasswordInput,
    TPhoneInput,
    TTextInput,
    TCpfInput,
    TSelect,
  },

  mounted() {
    this.setNameRules();
    this.setConfirmEmailRules();
    this.setConfirmPasswordRules();
    this.getGenders();
  },

  data: () => ({
    emailErrorBag: [] as string[],
    confirmEmail: "",
    nameRules: [] as Array<(v?: string) => boolean | string>,
    confirmEmailRules: [] as Array<(v?: string) => boolean | string>,
    confirmPassword: "",
    confirmPasswordRules: [] as Array<(v?: string) => boolean | string>,
    items: ["Brasileiro", "Argentino", "Alemão"],
    showPassword: false,
    valid: true,
    genders: [] as Array<GenderData>,
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
  },

  methods: {
    async getGenders() {
      const genders = await getAvaibleGenders();
      this.genders = genders.data;
    },
    setNameRules() {
      this.nameRules = [
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (v?: string): boolean | string =>
          !this.user.name || this.user.name.length > 2 || "O nome precisa ter mais que 2 letras.",
      ] as Array<(v?: string) => boolean | string>;
    },
    setConfirmEmailRules() {
      this.confirmEmailRules = [
        (v?: string): boolean | string =>
          !this.user.email || v === this.user.email || "Os e-mails informados são diferentes",
      ] as Array<(v?: string) => boolean | string>;
    },
    setConfirmPasswordRules() {
      this.confirmPasswordRules = [
        (v?: string): boolean | string =>
          !this.user.password || v === this.user.password || "As senhas informadas são diferentes",
      ] as Array<(v?: string) => boolean | string>;
    },
    async checkEmail() {
      const result = await userExists("email", this.user.email);

      if (result) {
        this.emailErrorBag = ["Esse e-mail já está em uso"];
      }
    },
    onEmailInput($event: string) {
      this.emailErrorBag = [];
      this.update("email", $event);
    },
    submit() {
      if (!this.form.validate()) return;

      this.$emit("submit");
    },
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
  },
});
