import { VuetifyThemeVariant } from "vuetify/types/services/theme";

const light: Partial<VuetifyThemeVariant> = {
  primary: "#f5333f",
  secondary: "#f5333f",
  tertiary: "#242424",
  "dark-background": "#242424",
  background: "#eee",
  "content-background": "#F8F8F8",
  heading: "#fff",
  body: "#D4D4D4",
};

export default { light };
