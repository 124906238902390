













import Vue from "vue";

import Snackbar from "@/components/core/Snackbar.vue";
import TheDialog from "@/components/core/TheDialog.vue";
import TheAppBar from "@/components/core/TheAppBar.vue";
import TheFooter from "@/components/core/TheFooter.vue";
import TheCookieDialog from "@/components/core/TheCookieDialog.vue";
import { Producer } from "@/config/producers";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";

export default Vue.extend({
  name: "App",
  components: {
    Snackbar,
    TheAppBar,
    TheFooter,
    TheCookieDialog,
    TheDialog,
  },

  data: () => ({
    //
  }),

  computed: {
    producer(): Producer {
      return this.$store.state.producers.selected as Producer;
    },
  },

  mounted() {
    this.changeTheme(this.producer.theme);
  },

  watch: {
    producer(old: Producer, _new: Producer) {
      this.changeTheme(_new.theme);
    },
  },

  methods: {
    changeTheme(theme: { light: Partial<VuetifyThemeVariant> }) {
      Object.keys(theme.light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = theme.light[i];
      });
    },
  },
});
