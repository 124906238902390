import Vue from "vue";

import pad from "./pad";
import upper from "./upper";
import cpf from "./cpf";
import currency from "./currency";

Vue.filter("pad", pad);
Vue.filter("upper", upper);
Vue.filter("cpf", cpf);
Vue.filter("currency", currency);
