<template>
  <div class="d-flex justify-center">
    <img
      alt="ClickerTicket Logo"
      :src="logo"
      v-bind="$attrs"
      v-on="$listeners"
      style="width: 100%; cursor: pointer"
      transition="scale-transition"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Logo from "@/assets/logo.svg";
import LogoDark from "@/assets/logo.dark.svg";

export default Vue.extend({
  name: "ClickerTicketLogo",
  props: {
    dark: {
      default: false,
      type: Boolean,
    },
    stretch: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    logo() {
      return this.dark ? LogoDark : Logo;
    },
  },
});
</script>
