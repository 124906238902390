import store from "@/store";
import { RouteConfig } from "vue-router";
import analytics from "@/plugins/analytics";

const Home = () => import(/* webpackChunkName: "home" */ "@/views/HomePage.vue");

const Login = () => import(/* webpackChunkName: "login" */ "@/views/UserLogin.vue");

const UserNew = () => import(/* webpackChunkName: "user-register" */ "@/views/User/CreateUser.vue");

const UserRecover = () => import(/* webpackChunkName: "user-recover" */ "@/views/User/Recover.vue");

const PaymentCheckout = () => import(/* webpackChunkName: "user-recover" */ "@/views/Ticket/PaymentCheckout.vue");

const Cancelamentos = () => import("@/views/CancelationPolicy.vue");

const DuvidasFrequentes = () => import("@/views/DuvidasFrequentes.vue");

const MeiaEntrada = () => import("@/views/MeiaEntrada.vue");

const Privacidade = () => import("@/views/PrivacyPolicy.vue");

const QuemSomos = () => import("@/views/QuemSomos.vue");

const Suporte = () => import("@/views/SupportPage.vue");

const TermosDeUso = () => import("@/views/TermosDeUso.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/:company?/cancelamentos",
    name: "Cancelamentos",
    component: Cancelamentos,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/faq",
    name: "DuvidasFrequentes",
    component: DuvidasFrequentes,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/meia-entrada",
    name: "MeiaEntrada",
    component: MeiaEntrada,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/privacidade",
    name: "Privacidade",
    component: Privacidade,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/sobre",
    name: "QuemSomos",
    component: QuemSomos,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/suporte",
    name: "Suporte",
    component: Suporte,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/termos",
    name: "TermosDeUso",
    component: TermosDeUso,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/register",
    name: "UserNew",
    component: UserNew,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/user/recover",
    name: "UserRecover",
    component: UserRecover,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/ticket/buy/:id",
    name: "PaymentCheckout",
    component: PaymentCheckout,
    props: true,
    beforeEnter(to, from, next) {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      if (store.state.cart.event_id === null) {
        store.commit("cart/setEventId", to.params.id);
      }

      if (store.state.cart.event_id !== to.params.id) {
        store.commit("cart/setEventId", to.params.id);
        store.commit("cart/reset");
      }

      if (to.params.id) {
        analytics.openedEventPage(to.params.id);
      }

      next();
    },
  },
];

export default routes.map((route) => {
  const meta = {
    public: true,
  };
  return { ...route, meta };
});
