











import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TTextInput",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredErrorMessage: {
      type: String,
      default: "Este campo é obrigatório",
    },
    rules: {
      type: Array as PropType<Array<(v: string | number) => boolean | string> | []>,
      default: () => [],
    },
  },

  computed: {
    getRules(): Array<(v: string | number) => boolean | string> | [] {
      const required = this.required ? [(v: string | number) => !!v || this.requiredErrorMessage] : [];

      return [...required, ...this.rules];
    },
  },

  methods: {
    validate(): boolean {
      const field = this.$refs["field"] as Vue & { validate(raise: boolean): boolean };
      return field.validate(true);
    },
  },
});
