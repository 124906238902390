import api from "@/api";
import OccupiedSeat from "@/types/Mapping/OccupiedSeat";
import ReservedSeat from "@/types/Mapping/ReservedSeat";
import { SelectionSeat } from "@/types/Mapping/SelectionSeat";
import { AxiosResponse } from "axios";

export type RequestPayload = {
  event_id: string;
  sector_id: string;
  seats: SelectionSeat[];
  // sections: {Array<{ id: string; seats: string[] }>};
};

type Response = {
  message: string;
};

export type SuccessResponse = Response;

export type ErrorResponse = Response & {
  updated: {
    reserved: ReservedSeat[];
    occupied: OccupiedSeat[];
  };
};

export default async function reserveSeats(payload: RequestPayload): Promise<SuccessResponse> {
  const response: AxiosResponse<SuccessResponse> = await api.post(`/api/mapping/seats/reservation`, payload);
  return response.data;
}
