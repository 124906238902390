import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import base from "@/assets/themes/base";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false, // Temporary until we handle themes properly
    themes: {
      ...base,
    },
  },
});
