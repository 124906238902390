
















import Vue, { PropType } from "vue";

import User from "@/types/User";

import TBtn from "@/components/core/TBtn.vue";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";
import { PasswordToken } from "@/api/requests/getPasswordRecoveryToken";
import TPasswordInput from "../core/TPasswordInput.vue";
import recoverPassword from "@/api/requests/recoverPassword";

export default Vue.extend({
  name: "FormValidateRecoverCode",

  components: {
    TBtn,
    TPasswordInput,
  },

  props: {
    value: {
      type: Object as PropType<User>,
      default: () => ({} as User),
    },
    token: {
      type: Object as PropType<PasswordToken>,
      required: true,
    },
  },

  data: () => ({
    valid: true,
    loading: false,
    password: "",
    password_confirm: "",
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    user(): User {
      return this.value;
    },
    rules() {
      return {
        password: [
          (v: string) => !!v || "O campo senha é obrigatório",
          (v: string) => (v && v.length >= 8) || "A senha deve ter no mínimo 8 caracteres",
        ],
        password_confirm: [(v: string) => (!!v && v === this.password) || "As senhas não coincidem"],
      };
    },
  },

  methods: {
    update(prop: string, value: string) {
      this.$emit("input", { ...this.user, [prop]: value });
    },
    async submit() {
      try {
        if (this.form.validate()) {
          this.loading = true;

          const token = await recoverPassword(this.token, this.password, this.user.document);

          this.$emit("submit", token);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          this.$snackbar(error.message, "error");
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
