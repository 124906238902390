export type DialogConfig = {
  hasTitle?: boolean;
  hasCloseButton?: boolean;
  persistent?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onClose?: () => any;
};

export interface State {
  visible: boolean;
  route: string;
  config: DialogConfig;
}

const state = {
  visible: false,
  route: "/",
  config: {
    hasTitle: true,
    hasCloseButton: true,
    persistent: true,
  },
} as State;

const mutations = {
  show(state: State, { route, config }: State): void {
    state.route = typeof route === "string" ? route : "/";
    state.visible = true;
    state.config = { ...state.config, ...config };
  },

  hide(state: State): void {
    if (state.config.onClose) {
      state.config.onClose();
      state.config.onClose = undefined;
    }

    state.visible = false;
    state.route = "/";
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
};
