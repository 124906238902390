import CreditCardPayment from "@/types/CreditCardPayment";
import NominalTicket from "@/types/NominalTicket";
import Order from "@/types/Order";
import PixPayment from "@/types/PixPayment";
import Reservation from "@/types/Reservation";
import Sector from "@/types/Sector";
import { RootState } from "..";
import cpf from "@/filters/cpf";
import { DateTime } from "luxon";
import SeatWithTicketType from "@/types/Mapping/SeatWithTicketType";
import { ActionContext } from "vuex";
import reserveTickets from "@/api/requests/reserveTickets";

type NominalTickets = Record<string, (NominalTicket & { seat?: string })[]>;

export interface State {
  sectors: Sector[];
  control: {
    step: number;
  };
  reservation: Reservation;
  order: Order;
  payment: PixPayment | CreditCardPayment | null;
  tickets: NominalTickets;
  event_id: string | null;
  timer: {
    isRunning: boolean;
    time?: string;
  };
}

const state: State = {
  sectors: [] as Sector[],
  control: {
    step: 1,
  },
  reservation: {} as Reservation,
  order: {} as Order,
  payment: null,
  tickets: {} as NominalTickets,
  event_id: null,
  timer: {
    isRunning: false,
    time: undefined,
  },
};

const mutations = {
  reset(state: State): void {
    state.sectors = [];
    state.control.step = 1;
    state.reservation = {} as Reservation;
    state.order = {} as Order;
    state.payment = null;
    state.tickets = {} as NominalTickets;
    state.timer = {
      isRunning: false,
      time: undefined,
    };
  },
  setSectors(state: State, sectors: Sector[]): void {
    const temp = state.sectors;

    for (const sector of sectors) {
      const tempSector = temp.filter((s) => s.id == sector.id)[0];
      if (!tempSector) continue;

      for (const section of sector.sections) {
        const tempSection = tempSector.sections.filter((s) => s.secao_id == section.secao_id)[0];
        if (!tempSector) continue;

        for (const type of section.tipos_ingresso) {
          const tempType = tempSection.tipos_ingresso.filter((t) => t.id == type.id)[0];

          if (!tempType) continue;

          type.quantidade = tempType.quantidade;
        }
      }
    }

    state.sectors = sectors;
  },
  setStep(state: State, value: number): void {
    state.control.step = value;
  },
  startTimer(state: State): void {
    state.timer = {
      isRunning: true,
      time: DateTime.now().toISOTime()!,
    };
  },
  resetTimer(state: State): void {
    state.timer = {
      isRunning: false,
      time: undefined,
    };
  },
  clearCart(state: State): void {
    state.sectors.forEach((sector) => {
      sector.sections.forEach((sections) => {
        sections.tipos_ingresso.forEach((type) => {
          type.quantidade = 0;
        });
      });
    });
  },
  // PRECISA ALTERAR ESSA PORRA POR VALOR PUTA QUE PARIU EU ODEIO JAVASCRIPT

  alterTicketTypeQuantity(
    state: State,
    { sector_id, section_id, type_id, delta }: { sector_id: string; section_id: string; type_id: string; delta: number }
  ): void {
    state.sectors = state.sectors.map((_sector) => {
      if (_sector.id !== sector_id) return _sector;

      _sector.sections = _sector.sections.map((_section) => {
        if (_section.secao_id !== section_id) return _section;

        _section.tipos_ingresso = _section.tipos_ingresso.map((_type) => {
          if (_type.id !== type_id) return _type;

          _type.quantidade = Math.max(0, _type.quantidade + delta);
          return _type;
        });

        return _section;
      });

      return _sector;
    });
  },
  setReserva(state: State, reservation: Reservation): void {
    state.reservation = reservation;
  },
  setPayment(state: State, payment: PixPayment | CreditCardPayment): void {
    state.payment = payment;
  },
  setEventId(state: State, id: string | null): void {
    state.event_id = id;
  },
};

const actions = {
  generateNominalTickets(
    { state, rootState }: { state: State; rootState: RootState },
    payload: { tickets: SeatWithTicketType[] }
  ): void {
    state.tickets = {};
    // Ensures that only the first ticket will have the name and document filled
    let firstTicket = true;

    for (const item of state.reservation.items) {
      const sector = state.sectors.filter((s) => s.id === item.sectorId)[0];

      const section = sector.sections.filter((s) => s.secao_id == item.secaoId)[0];

      const type = section.tipos_ingresso.filter((t) => t.id == item.tipoIngressoId)[0];

      if (payload?.tickets) {
        const tickets = payload.tickets.filter((t) => t.type.id === type.id);

        tickets.forEach((t) => {
          if (state.tickets[sector.id] === undefined) {
            state.tickets[sector.id] = [];
          }

          state.tickets[sector.id].push({
            name: t.nominal.name,
            cpf: cpf(t.nominal.cpf!),
            type: type.nome,
            preco: type.preco,
            typeId: type.id,
            seat: `${t.seat.symbol}${t.seat.number}`,
          });
        });
      } else {
        for (let i = 0; i < item.quantidade; i++) {
          if (state.tickets[sector.id] === undefined) {
            state.tickets[sector.id] = [];
          }

          state.tickets[sector.id].push({
            name: firstTicket ? rootState.user.data.name : null,
            cpf: firstTicket ? cpf(rootState.user.data.document) : null,
            email: firstTicket ? rootState.user.data.email : undefined,
            cellphone: firstTicket ? rootState.user.data.cellphone : undefined,
            type: type.nome,
            preco: type.preco,
            typeId: type.id,
          });
        }

        if (firstTicket) {
          firstTicket = false;
        }
      }
    }
  },
  updateTicketTypeAmountFromMapping(
    context: ActionContext<State, RootState>,
    { tickets, sector_id }: { tickets: SeatWithTicketType[]; sector_id: string }
  ): void {
    context.commit("clearCart");

    tickets.forEach((ticket) => {
      context.commit("alterTicketTypeQuantity", {
        sector_id: sector_id,
        section_id: ticket.seat.section_id,
        type_id: ticket.type.id,
        delta: 1,
      });
    });
  },
  async createReservation(
    context: ActionContext<State, RootState>,
    { event_id, token, seats }: { event_id: string; token: string; seats?: SeatWithTicketType[] }
  ): Promise<void> {
    const old = localStorage.getItem("reservation");

    let reservation = null;

    if (old !== null) {
      reservation = JSON.parse(old as string);
    }

    const response = await reserveTickets({
      event_id: event_id,
      sectors: context.state.sectors,
      reserva_anterior_id: reservation !== null ? reservation.id : null,
      token,
      seats,
    });

    localStorage.setItem("reservation", JSON.stringify(response));

    context.commit("setReserva", response);
    context.dispatch("generateNominalTickets", { tickets: seats });
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
};
