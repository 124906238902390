












import Vue, { PropType } from "vue";

import { cpf } from "cpf-cnpj-validator";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TCpfInput",

  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array as PropType<Array<(v: string) => boolean | string> | []>,
      default: () => [],
    },
  },

  components: {
    TTextInput,
  },

  computed: {
    validationRules(): Array<(v: string) => boolean | string> | [] {
      const cpfValidateRule = (v: string): boolean | string => cpf.isValid(v, true) || "CPF inválido";

      return [...this.rules, cpfValidateRule];
    },
  },

  methods: {
    validate(): boolean {
      const field = this.$refs["field"] as Vue & { validate(): boolean };
      return field.validate();
    },
  },
});
