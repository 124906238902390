<template>
  <div class="d-flex justify-center">
    <img
      alt="ClickerTicket Logo"
      :src="logo"
      v-bind="$attrs"
      v-on="$listeners"
      style="width: 100%; cursor: pointer"
      transition="scale-transition"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Logo from "@/assets/logo.svg";
import LogoDark from "@/assets/logo.dark.svg";

export default Vue.extend({
  name: "ClickerTicketLogoHeader",
  props: {
    dark: {
      default: false,
      type: Boolean,
    },
    stretch: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    logo() {
      const producer = this.$store.state.producers.selected;

      if (producer.id != "0") {
        return `https://storage.torcedordevantagens.com.br/ticket/${producer.id}/logo.svg`;
      }

      return this.dark ? LogoDark : Logo;
    },
  },
});
</script>
