import { ActionContext } from "vuex";

import { RootState } from "..";
import { event } from "@/plugins/fbq";
import { Producer, producers, initial } from "@/config/producers";

export interface State {
  producers: Producer[];
  selected: Producer | null;
}

const state: State = {
  producers,
  selected: initial,
};

const mutations = {
  set(state: State, producer: Producer): void {
    state.selected = producer;
  },
  reset(state: State): void {
    state.producers = producers;
  },
};

const actions = {
  setBySlug(context: ActionContext<State, RootState>, slug: string): void {
    const found = context.state.producers.find((p) => p.slug === slug);

    if (found === undefined) {
      const def = state.producers.find((p) => p.slug === "");
      context.commit("set", def);
    } else {
      context.commit("set", found);
    }
  },
  analytics(
    context: ActionContext<State, RootState>,
    { producer_id, action, params }: { producer_id: string; action: string; params?: Record<string, string> }
  ): void {
    const producer = context.state.producers.find((p) => p.id == producer_id);
    if (!producer || !producer.analytics || !producer.analytics.meta) return;
    event(producer.analytics.meta, action, params);
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  actions,
};
