import getSectorRows from "@/api/requests/getSectorRows";
import Row from "@/types/Mapping/Row";
import { SeatStatus, SelectionSeat } from "@/types/Mapping/SelectionSeat";
import Sector from "@/types/Sector";
import { DateTime } from "luxon";
import { ActionContext, GetterTree } from "vuex";
import { RootState } from "..";
import ReservedSeat from "@/types/Mapping/ReservedSeat";
import OccupiedSeat from "@/types/Mapping/OccupiedSeat";
import reserveSeats, { ErrorResponse as ReserveErrorResponse } from "@/api/requests/reserveSeats";
import axios, { AxiosResponse } from "axios";
import SeatWithTicketType from "@/types/Mapping/SeatWithTicketType";
import Section from "@/types/Section";
import TicketType from "@/types/TicketType";
import User from "@/types/User";
import Event from "@/types/Event";

const DURATION = 60 * 15; /* 15 minutes */

export interface State {
  stepper: {
    step: number;
  };
  timer: {
    counter: number;
    startedAt: number;
  };
  selected: {
    sector: null | Sector;
    seats: SelectionSeat[];
    tickets: SeatWithTicketType[];
  };
  rows: Row[];
}

const state: State = {
  stepper: {
    step: 1,
  },
  timer: {
    counter: 0,
    startedAt: 0,
  },
  selected: {
    sector: null,
    seats: [],
    tickets: [],
  },
  rows: [],
};

const mutations = {
  setSector(state: State, sector: Sector): void {
    state.selected.sector = sector;
  },
  toggleSeat(state: State, { seat, limit }: { seat: SelectionSeat; limit: number }): void {
    const selected = state.selected.seats.find((s: SelectionSeat) => s === seat);

    if (selected) {
      seat.status = "empty";
      state.selected.seats.splice(state.selected.seats.indexOf(seat), 1);
    } else if (state.selected.seats.length < limit) {
      seat.status = "selected";
      state.selected.seats.push(seat);
    }
  },
  reserveSeats(state: State, seats: SelectionSeat[]): void {
    state.selected.seats = seats;
    state.timer.startedAt = DateTime.now().toSeconds();
  },
  clearReservation(state: State): void {
    state.selected.seats = [];
    state.selected.tickets = [];
    state.timer.startedAt = 0;

    state.rows.forEach((r: Row) =>
      r.seats?.forEach((s: SelectionSeat) => {
        if (s.status === "selected") s.status = "empty";
      })
    );
  },
  clearTimer(state: State): void {
    state.timer.startedAt = 0;
  },
  setStep(state: State, step: number): void {
    state.stepper.step = step;
  },
};

const getters: GetterTree<State, unknown> = {
  isTimerActive({ timer }: State): boolean {
    return timer.startedAt + DURATION > DateTime.now().toSeconds();
  },
  section(state: State): Section | null {
    if (state.selected.sector == null) return null;

    const seat = state.selected.seats[0];

    if (seat === undefined) return null;

    return state.selected.sector.sections.find((section) => section.secao_id === seat.section_id)!;
  },
  types(state: State, getters: { section: Section }): TicketType[] {
    return getters.section.tipos_ingresso;
  },
};

const actions = {
  async fetchRows(context: ActionContext<State, RootState>, { event }: { event: Event }): Promise<void> {
    const sector = context.state.selected.sector;

    if (sector === null) return;

    context.commit("loader/increment", undefined, { root: true });

    context.state.rows = await getSectorRows(event.id, sector.id);

    context.dispatch("mountRows");

    context.commit("clearReservation");

    context.commit("loader/decrement", undefined, { root: true });
  },
  mountRows(context: ActionContext<State, RootState>): void {
    const sorted = context.state.rows.toSorted((a: Row, b: Row) => {
      if (a.interval.end - a.interval.start > b.interval.end - b.interval.start) return -1;
      return 1;
    });

    const longest = sorted[0];

    context.state.rows = context.state.rows.map((row: Row) => {
      const quantity = longest.interval.end - longest.interval.start;

      const seats: SelectionSeat[] = Array.from({ length: quantity }, (_, i) => {
        const code = i + 1;

        return {
          row_id: row.id,
          section_id: row.section_id,
          symbol: row.symbol,
          number: code,
          status: getStatusFromIndex(row, code),
          valid: code + row.interval.start <= row.interval.end,
        };
      });

      return {
        ...row,
        seats,
      };
    });
  },
  async reserveSeats(context: ActionContext<State, RootState>, { event_id }: { event_id: string }): Promise<void> {
    if (context.state.selected.sector === null) throw new Error("Setor não selecionado");

    if (context.state.selected.seats.length === 0) throw new Error("Você deve escolher pelo menos um lugar");

    try {
      await reserveSeats({
        event_id: event_id,
        sector_id: context.state.selected.sector.id,
        seats: context.state.selected.seats,
      });

      context.dispatch("mountTickets");

      state.timer.startedAt = DateTime.now().toSeconds();
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        const { data } = e.response as AxiosResponse<ReserveErrorResponse>;

        context.commit("clearReservation");

        if (data.updated) {
          context.dispatch("updateSeats");
          context.dispatch("mountRows");
          throw new Error(data.message);
        } else {
          throw new Error("Erro! Tente novamente mais tarde.");
        }
      }
    }
  },
  updateSeats(
    context: ActionContext<State, RootState>,
    data: { reserved: ReservedSeat[]; occupied: OccupiedSeat[] }
  ): void {
    context.state.rows = context.state.rows.map((r: Row) => ({
      ...r,
      occupied: data.occupied.filter((s) => s.row_id == r.id),
      reserved: data.reserved.filter((s) => s.row_id == r.id),
    }));
  },
  mountTickets(context: ActionContext<State, RootState>): void {
    if (context.state.selected.sector === null) throw new Error("Setor não selecionado");

    const user: User = context.rootState.user.data;

    context.state.selected.tickets = context.state.selected.seats.map((seat: SelectionSeat, i: number) => ({
      seat,
      nominal: {
        type: "",
        typeId: "",
        name: i === 0 ? user.name : "",
        cpf: i === 0 ? user.document : "",
        preco: "",
        email: i === 0 ? user.email : "",
        cellphone: i === 0 ? user.cellphone : "",
      },
      type: context.getters.types[0],
    }));

    context.dispatch(
      "cart/updateTicketTypeAmountFromMapping",
      {
        tickets: context.state.selected.tickets,
        sector_id: context.state.selected.sector.id,
      },
      { root: true }
    );
  },
  changeTypeFromSeat(context: ActionContext<State, RootState>): void {
    if (context.state.selected.sector === null) throw new Error("Setor não selecionado");

    context.dispatch(
      "cart/updateTicketTypeAmountFromMapping",
      {
        tickets: context.state.selected.tickets,
        sector_id: context.state.selected.sector.id,
      },
      { root: true }
    );
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};

function getStatusFromIndex(row: Row, index: number): SeatStatus {
  if (index > row.interval.end || index < row.interval.start) return "invalid";

  const foundReserved = row.reserved.find((seat: ReservedSeat) => `${row.symbol}${index}` === seat.number);

  if (foundReserved !== undefined) {
    return "reserved";
  }

  const foundOccupied = row.occupied.find((seat: OccupiedSeat) => `${row.symbol}${index}` === seat.number);

  if (foundOccupied !== undefined) {
    return "occupied";
  }

  return "empty";
}
