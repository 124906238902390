<template>
  <v-container fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12" md="10">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TContainer",
};
</script>
