import { store as user, State as UserState } from "./user";
import { store as snackbar, State as SnackbarState } from "./snackbar";
import { store as ticket, State as TicketState } from "./tickets";
import { store as dialog, State as DialogState } from "./dialog";
import { store as events, State as EventsState } from "./events";
import { store as app, State as AppState } from "./app";
import { store as cart, State as CartState } from "./cart";
import { store as producers, State as ProducersState } from "./producers";
import { store as mapping, State as MappingState } from "./mapping";
import { store as loader, State as LoaderState } from "./loader";
import { store as categories, State as CategoryState } from "./categories";

export interface State {
  user: UserState;
  snackbar: SnackbarState;
  dialog: DialogState;
  ticket: TicketState;
  events: EventsState;
  app: AppState;
  cart: CartState;
  producers: ProducersState;
  mapping: MappingState;
  loader: LoaderState;
  categories: CategoryState;
}

const modules = {
  snackbar,
  user,
  ticket,
  dialog,
  events,
  app,
  cart,
  producers,
  mapping,
  loader,
  categories,
};

export default modules;
