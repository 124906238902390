











import Vue, { PropType } from "vue";

import TTextInput from "@/components/core/TTextInput.vue";

export default Vue.extend({
  name: "TPasswordInput",

  components: {
    TTextInput,
  },

  props: {
    show: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    value: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    localShow: false,
  }),

  computed: {
    password: {
      get(): string {
        return this.value;
      },
      set(password: string) {
        this.$emit("input", password);
      },
    },
    showPassword: {
      get(): boolean {
        return this.show === undefined ? this.localShow : this.show;
      },
      set(status: boolean) {
        if (this.show === undefined) this.localShow = status;
      },
    },
  },
});
