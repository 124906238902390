







import Vue from "vue";

export default Vue.extend({
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
});
