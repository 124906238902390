import Vue from "vue";
import App from "./App.vue";

import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import api from "./api";

import vuetify from "./plugins/vuetify";
import snackbar from "./plugins/snackbar";
import dialog from "./plugins/dialog";
import { $loader } from "./plugins/loader";
import { date } from "./plugins/date";
import { analytics } from "./plugins/analytics";
import { settings } from "./plugins/settings";

import "@/plugins/mask";
import "@/plugins/seo";
import "@/plugins/date";
import "@/plugins/recaptcha";

import "@/filters";
import "@/styles/sass/_app.scss";
import "@/components";

Vue.config.productionTip = false;

Vue.prototype.$api = api;
Vue.prototype.$snackbar = snackbar;
Vue.prototype.$dialog = dialog;
Vue.prototype.$loader = $loader;
Vue.prototype.$date = date;
Vue.prototype.$analytics = analytics;
Vue.prototype.$settings = settings;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
