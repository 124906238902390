import { RouteConfig } from "vue-router";
import store from "@/store";

const TicketList = () => import(/* webpackChunkName: "ticket-buy" */ "@/views/Ticket/PaymentDetails.vue");

const Payments = () => import(/* webpackChunkName: "ticket-buy-details" */ "@/views/Ticket/PaymentListing.vue");

const UserData = () => import(/* webpackChunkName: "user-data" */ "@/views/User/UserData.vue");

const routes: Array<RouteConfig> = [
  {
    path: "/:company?/ticket/payments",
    name: "Payments",
    component: Payments,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
  {
    path: "/:company?/ticket/list/:id",
    name: "TicketList",
    component: TicketList,
    beforeEnter(to, from, next) {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      store.commit("cart/reset");
      store.commit("cart/setEventId", null);
      if (Object.keys(store.state.ticket.selectedOrder).length == 0) {
        next({ name: "Payments" });
      } else {
        next();
      }
    },
  },
  {
    path: "/:company?/user/data",
    name: "UserData",
    component: UserData,
    beforeEnter: (to, from, next) => {
      if (to.params && to.params.company !== undefined) {
        store.dispatch("producers/setBySlug", to.params.company);
      }

      next();
    },
  },
];

export default routes.map((route) => {
  const meta = {
    public: false,
  };
  return { ...route, meta };
});
