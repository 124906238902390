import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "pt-BR";

export const date = {
  parse(input: string, format = "y-MM-dd HH:mm:ss"): DateTime {
    return DateTime.fromFormat(input, format);
  },
  format(input: string, outputFormat: string, inputFormat = "y-MM-dd HH:mm:ss"): string {
    const parsed = DateTime.fromFormat(input, inputFormat);
    return parsed.toFormat(outputFormat);
  },
  now(): DateTime {
    return DateTime.now();
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Vue from "vue";

declare module "vue/types/vue" {
  interface Vue {
    $date: {
      now(): DateTime;
      parse(input: string, format?: string): DateTime;
      format(input: string, outputFormat: string, inputFormat?: string): string;
    };
  }
}
