



























import Vue from "vue";

export default Vue.extend({
  methods: {
    submit(value: boolean) {
      this.$store.commit("app/cookie", value);
    },
  },
  computed: {
    show: {
      get() {
        return !this.$store.state.app.cookies.prompted;
      },
      set(value) {
        return value;
      },
    },
  },
});
