








































import Vue, { PropType } from "vue";

export interface Step {
  complete?: boolean;
  editable?: boolean;
  step: number;
  label?: string;
  mobileLabel?: string;
  click?: (model: number) => unknown;
  onSubmit?: () => boolean;
  errors?: Record<string, boolean>;
}

export default Vue.extend({
  name: "TStepper",

  props: {
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    value: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    getStepColor(step: number, complete: boolean): string | undefined {
      if (step === this.model) return "primary";

      if (complete) return "tertiary";

      return;
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(step: number) {
        this.$emit("input", step);
      },
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
  },
});
