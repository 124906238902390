import api from "@/api";
import RecoveryMethod from "@/types/RecoveryMethod";

export default async function sendPasswordRecoveryCode(document: string, method: RecoveryMethod): Promise<boolean> {
  document = document.replace(/\D/g, "");

  await api.post("/api/password/code", { document, method: method.name });

  return true;
}
