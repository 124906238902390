import { VuetifyThemeVariant } from "vuetify/types/services/theme";
import { themes } from "@/assets/themes";

export interface Producer {
  id: string;
  slug: string;
  title: string;
  theme: {
    light: Partial<VuetifyThemeVariant>;
    dark?: Partial<VuetifyThemeVariant>;
  };
  analytics?: {
    meta?: string;
  };
}

export const producers = [
  {
    id: "0",
    slug: "",
    title: "Ingresso de Vantagens",
    theme: themes.base,
  },
  {
    id: "655a49a0-5408-11ee-a5af-2388dbba5fcc",
    slug: "america",
    title: "América FC",
    theme: themes.america,
  },
  {
    id: "f22a4430-c4d9-11ec-b892-ef25fe858e6b",
    slug: "jec-futsal",
    title: "JEC Krona",
    theme: themes.jec,
  },
  {
    id: "68dbf0a0-ca91-11e9-83a8-8b4b91c36339",
    slug: "jec",
    title: "JEC",
    theme: themes.jec,
  },
  {
    id: "c0a43340-b476-11ee-901b-bd30cdc86c8d",
    slug: "barra",
    title: "Barra FC",
    theme: themes.barra,
    analytics: {
      meta: "218154797957065",
    },
  },
  {
    id: "0de57220-b12b-11e8-8ee6-5f76827d02a6",
    slug: "dataclick",
    title: "DTKSports",
    theme: themes.base,
    analytics: {
      meta: "230989696734366",
    },
  },
];

export const initial = {
  id: "0",
  slug: "",
  title: "Ingresso de Vantagens",
  theme: themes.base,
};
