




























import Vue from "vue";

import User from "@/types/User";

import FormNewUser from "@/components/User/FormNewUser.vue";
import FormVerifyUser from "@/components/User/FormVerifyUser.vue";
import TCard from "@/components/core/TCard.vue";
import TClickerTicketLogo from "@/components/core/TClickerTicketLogo.vue";
import userCreate from "@/api/requests/userCreate";

import { DialogConfig } from "@/store/modules/dialog";
import Address from "@/types/Address";
import getAccessToken from "@/api/requests/getAccessToken";
import getUserData from "@/api/requests/getUserData";
import { DateTime } from "luxon";
import axios, { AxiosError } from "axios";
import ErrorResponse from "@/types/ErrorResponse";

type ResetableForm = Vue & { reset: () => undefined };

export default Vue.extend({
  name: "UserRegister",

  components: {
    FormNewUser,
    FormVerifyUser,
    TCard,
    TClickerTicketLogo,
  },

  data: () => ({
    verified: false,
    user: {
      address: {
        country: "Brazil",
      } as Address,
    } as User,
    loading: false,
  }),

  methods: {
    async createUser() {
      try {
        this.loading = true;

        this.user.username = this.user.name.replace(/[\W_]+/g, "").toLowerCase();

        this.user.birth_date = this.formatDate(this.user.birth_date, "y-MM-dd", "dd/MM/y");

        const registered = await userCreate(this.user);

        if (registered) {
          await this.login();
        } else {
          throw "Erro ao tentar realizar cadastro";
        }

        this.loading = false;
        this.$snackbar("Cadastro realizado com sucesso!", "success");
        this.close();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError<ErrorResponse>;

          this.$snackbar(error.response?.data.message, "error");

          // Reseting birth date to visual format
          this.user.birth_date = this.formatDate(this.user.birth_date, "dd/MM/y", "y-MM-dd");

          this.form.reset();
          this.loading = false;
        } else {
          console.log(e);
        }
      }
    },
    formatDate(date: string, format: string, input_format = "y-MM-dd HH:mm:ss"): string {
      const parsed = DateTime.fromFormat(date, input_format);
      return parsed.toFormat(format);
    },
    onVerifyUser(userExists: boolean) {
      if (userExists) {
        this.$dialog.show("/recover");

        this.$snackbar("CPF já cadastrado em nossa base, recupere sua senha!", "error");
      }
      userExists ? this.$dialog.show("/recover") : (this.verified = true);

      /*
       * se o usuário já tiver cadastro, redirect pra recuperação de senha
       * do contrário, renderiza form dos dados do usuário
       */
    },
    async login() {
      const { data } = await getAccessToken(this.user.email, this.user.password);
      this.$store.commit("user/setToken", data);
      const user = await (await getUserData()).data;
      this.$store.commit("user/setUser", user);
    },
    close() {
      this.$dialog.hide();
    },
  },

  computed: {
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
    form(): ResetableForm {
      return this.$refs.form as ResetableForm;
    },
  },
});
