





































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TSelect",

  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredErrorMessage: {
      type: String,
      default: "Este campo é obrigatório",
    },
    rules: {
      type: Array as PropType<Array<(v: string | number) => boolean | string> | []>,
      default: () => [],
    },
    withIcons: {
      type: Boolean,
      default: false,
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "value",
    },
  },

  computed: {
    getRules(): Array<(v: string | number) => boolean | string> | [] {
      const required = this.required ? [(v: string | number) => !!v || this.requiredErrorMessage] : [];

      return [...this.rules, ...required];
    },
  },
});
