




































































































import { DateTime } from "luxon";
import Vue from "vue";

import TClickerTicketLogo from "./TClickerTicketLogo.vue";
import TSectionHeader from "./TSectionHeader.vue";

export default Vue.extend({
  name: "TheFooter",

  components: {
    TClickerTicketLogo,
    TSectionHeader,
  },

  data: () => ({
    items: [
      {
        title: "Institucional",
        links: [
          {
            label: "Quem Somos",
            to: "/sobre",
          },
          {
            label: "Termos de Uso",
            to: "/termos",
          },
        ],
      },

      {
        title: "Nossas Políticas",
        links: [
          {
            label: "De Privacidade",
            to: "/privacidade",
          },
          {
            label: "De Cancelamento",
            to: "/cancelamentos",
          },
          {
            label: "De Meia-entrada",
            to: "/meia-entrada",
          },
        ],
      },

      {
        title: "Contato",
        links: [
          {
            label: "Fale Conosco",
            to: "/suporte",
          },
          {
            label: "Dúvidas Frequentes",
            to: "/faq",
          },
        ],
      },
    ],

    whatsAppColor: "#25d366",
  }),

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    year() {
      return DateTime.now().year;
    },
  },
});
