

































import Vue from "vue";
import TCard from "@/components/core/TCard.vue";
import { DialogConfig } from "@/store/modules/dialog";
import Logo from "@/assets/img/logo-onda-verde.png";

export default Vue.extend({
  name: "AcessoSocio",

  components: {
    TCard,
  },

  data: () => ({
    valid: false,
    email: "",
    loading: false,
    password: "",
    imageSrc: Logo,
  }),

  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.form as Vue & { validate: () => boolean };
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
  },

  methods: {
    login() {
      this.$dialog.show("/login/socio");
    },
    close() {
      this.$dialog.hide();
    },
  },
});
