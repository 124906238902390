import store from "@/store";
import { DialogConfig } from "@/store/modules/dialog";

export default {
  show(route: string, config?: DialogConfig): void {
    store.commit("dialog/show", { route, config });
  },
  hide(): void {
    store.commit("dialog/hide");
  },
};
