var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({attrs:{"outlined":"","rules":_vm.getRules,"background-color":_vm.backgroundColor},scopedSlots:_vm._u([(_vm.withIcons)?{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)],1)]}}:null,(_vm.withIcons)?{key:"selection",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-col',{staticClass:"d-flex align-center ps-8"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)]}}:null],null,true)},'v-select',Object.assign({}, _vm.$props, _vm.$attrs),false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }