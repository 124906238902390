import api from "@/api";
import User from "@/types/User";

interface UserExistsResponse {
  message: string;
  data: User;
}

export default async function userCreate(user: User): Promise<boolean> {
  await api.post<UserExistsResponse>("/api/user", user);
  return true;
}
