import Order from "@/types/Order";

export const analytics = {
  appDownload(): void {
    gtag("event", "app_download", { standalone: "true" });
  },
  openedEventPage(eventId: string): void {
    gtag("event", "opened_event_page", {
      event_name: "select_content",
      content_type: "event_id",
      content_id: eventId,
    });
  },
  purchase(order: Order): void {
    gtag("event", "purchase", {
      currency: "BRL",
      value: order.valor_total,
      transaction_id: order.id,
      items: order.items.map((item) => ({
        item_id: item.id,
        item_name: item.tipo,
        price: item.valor,
        quantity: item.quantidade,
        item_category: item.nome_cortesia ? "Cortesia" : "Normal",
        item_variant: order.evento_id,
      })),
    });
  },
};

export default { ...analytics };

declare module "vue/types/vue" {
  interface Vue {
    $analytics: {
      appDownload(): void;
      openedEventPage(eventId: string): void;
      purchase(order: Order): void;
    };
  }
}
