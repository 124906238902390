import api from "@/api";

export interface PasswordToken {
  token: string;
}

export default async function getPasswordRecoveryToken(document: string, code: string): Promise<PasswordToken> {
  document = document.replace(/\D/g, "");

  const response = await api.post<PasswordToken>("/api/password/check", {
    document,
    code,
  });

  return response.data;
}
