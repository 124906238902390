















import { DialogConfig } from "@/store/modules/dialog";
import Event from "@/types/Event";
import Vue from "vue";

export default Vue.extend({
  computed: {
    event(): Event {
      return this.$store.state.events.selected;
    },
    dialog(): DialogConfig {
      return this.$store.state.dialog;
    },
    imageUrl(): string {
      return `https://office.ingressodevantagens.com.br/evento/${this.event.id}/imagemEstadio`;
    },
  },
  methods: {
    close() {
      this.$dialog.hide();
    },
  },
});
