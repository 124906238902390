import api from "@/api";
import { AxiosResponse } from "axios";
import OAuthToken from "@/types/OAuthToken";

async function getAccessToken(email: string, password: string): Promise<AxiosResponse<OAuthToken>> {
  return await api.post<OAuthToken>("/oauth/token", {
    username: email,
    password: password,
    client_id: process.env.VUE_APP_API_ID,
    client_secret: process.env.VUE_APP_API_SECRET,
    grant_type: "password",
  });
}

export default getAccessToken;
