import analytics from "@/plugins/analytics";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

interface InstalledApp {
  id: string | null;
  platform: string;
  url: string | null;
  version: string | null;
}

declare global {
  interface Window {
    pwa: null | BeforeInstallPromptEvent;
    PagSeguro: {
      encryptCard(params: {
        publicKey: string;
        holder: string;
        number: string;
        expMonth: string;
        expYear: string;
        securityCode: string;
      }): {
        encryptedCard: string;
        hasErrors: boolean;
        errors: {
          code: string;
          message: string;
        }[];
      };
    };
  }

  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }

  interface Navigator {
    getInstalledRelatedApps(): Promise<InstalledApp[]>;
  }
}

export interface State {
  version: string;
  commit: string;
  pwa: {
    enabled: boolean;
    installed: boolean;
    event: BeforeInstallPromptEvent | null;
  };
  cookies: {
    prompted: boolean;
    allowed: boolean;
  };
}

const state: State = {
  version: process.env.VUE_APP_VERSION,
  commit: process.env.VUE_APP_GIT_HASH,
  pwa: {
    enabled: true,
    installed: false,
    event: null,
  },
  cookies: {
    prompted: false,
    allowed: false,
  },
};

const mutations = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async pwa(state: State): Promise<void> {
    if (window.pwa == null) return;

    window.pwa.prompt();

    const { outcome } = await window.pwa.userChoice;

    // Dispatch analytics event for tracking downloads
    if (outcome == "accepted") {
      analytics.appDownload();
    }
  },
  cookie(state: State, value: boolean): void {
    state.cookies.prompted = true;
    state.cookies.allowed = value;
  },
};

window.addEventListener("beforeinstallprompt", (e: BeforeInstallPromptEvent) => {
  e.preventDefault();
  window.pwa = e;
});

async function setup() {
  if ("getInstalledRelatedApps" in window.navigator) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const apps = await window.navigator.getInstalledRelatedApps();
  }
}

setup();

export const store = {
  namespaced: true,
  state,
  mutations,
};
