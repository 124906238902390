import api from "@/api";
import DataResponse from "@/types/DataResponse";
import RecoveryMethod from "@/types/RecoveryMethod";

export default async function getPasswordRecoveryMethods(document: string): Promise<RecoveryMethod[]> {
  document = document.replace(/\D/g, "");

  const response = await api.post<DataResponse<RecoveryMethod[]>>("/api/password/search", { document });

  return response.data.data;
}
