import Category from "@/types/Category";

export interface State {
  data: Category[];
}

const state: State = {
  data: [
    {
      id: "",
      title: "Todas as Categorias",
      icon: "mdi-circle",
      tag: "",
      order: 0,
    },
  ] as Category[],
};

const mutations = {
  setCategories(state: State, categories: Category[]): void {
    state.data = [
      ...[
        {
          id: "",
          title: "Todas as Categorias",
          icon: "mdi-circle",
          tag: "",
          order: 0,
        },
      ],
      ...categories,
    ];
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
};
