export interface State {
  visible: boolean;
  message: string;
  timeout: number;
  color: string;
}

const state = {
  visible: false,
  message: "",
  timeout: 5000,
  color: "",
} as State;

const mutations = {
  show(_state: State, { message, timeout, color }: State): void {
    _state.message = typeof message === "string" ? message.toUpperCase() : "";
    _state.timeout = timeout || 5000;
    _state.color = color;
    _state.visible = true;
  },

  hide(_state: State): void {
    _state.visible = false;
    _state.color = "";
    _state.message = "";
  },
};

export const store = {
  namespaced: true,
  state,
  mutations,
};
