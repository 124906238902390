import OAuthToken from "@/types/OAuthToken";
import User from "@/types/User";

export interface State {
  data: User;
  logged: boolean;
  token: OAuthToken | null;
}

const state: State = {
  data: {} as User,
  logged: false,
  token: null,
};

const mutations = {
  setUser(state: State, user: User): void {
    state.data = { ...user };
    state.logged = true;
  },
  setToken(state: State, token: OAuthToken): void {
    state.token = token;
  },
  // setPlano(state: State, planos: Plan[]): void {
  //   state.data = { ...state.data, planos };
  // },
  logout(state: State): void {
    state.data = {} as User;
    state.logged = false;
  },
};

const getters = {
  id: (state: State): string => state.data.id,
};

export const store = {
  namespaced: true,
  state,
  mutations,
  getters,
};
