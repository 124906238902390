import Vue from "vue";
import VueRouter from "vue-router";
import publicRoutes from "@/router/routes/public";
import privateRoutes from "@/router/routes/private";
import store from "@/store";
import dialog from "@/plugins/dialog";
import getUserData from "@/api/requests/getUserData";

Vue.use(VueRouter);

const routes = publicRoutes.concat(privateRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  scrollBehavior(to, from, savedPosition) {
    return {
      selector: to.hash != "" ? to.hash : "#scroll-anchor",
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    if (typeof to.query.token === "string") {
      // For admins. Authenticates the user if a valid token is provided
      const token = JSON.parse(atob(to.query.token));

      store.commit("user/setToken", {
        token_type: "password",
        access_token: token.accessToken,
        refresh_token: "",
        expires_in: 60 * 60,
      });

      const user = await getUserData();

      store.commit("user/setUser", user.data);

      next("/user/data");
    }
  }
  if (!to.matched.some((record) => record.meta.public)) {
    if (!store.state.user.logged) {
      dialog.show("/", {
        hasTitle: false,
        hasCloseButton: false,
      });
    }
  }
  next();
});

export default router;
